import * as React from "react";
import { graphql } from "gatsby";
//import { Link } from "gatsby";
//import { GatsbyImage } from "gatsby-plugin-image";
//import { Container } from 'react-bootstrap';
//import { Row } from 'react-bootstrap';
//import { Col } from 'react-bootstrap';

import WorkDetail from "../../components/parts/work-detail";
import WorkDetailHero from "../../components/parts/work-detail-hero";
import IframeYouTube from "../../components/parts/iframe-youtube";
//import IframeVimeo from "../../components/parts/iframe-vimeo";
import WorkDetailImageGallery from "../../components/parts/work-detail-image-gallery";
import WorkDetailImages from "../../components/parts/work-detail-images";
//import WorkDetailMiddleSection from "../../components/parts/work-detail-middle-section";
//import WorkDetailCredit from "../../components/parts/work-detail-credit";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/work-detail.scss";

const WorkDetailPage = ({ location, data }) => {

  const imageNodes = data.images.edges;
  const ogpImage = (!data.ogpImage) ? null : data.ogpImage.publicURL;

  /*
  const HeroContent = () => {
    return (
      <WorkDetailHero className="heroNoTrimming">
        <GatsbyImage
          image={imageNodes[0].node.childImageSharp.gatsbyImageData}
          objectPosition="50% 50%"
          layout="constrained"
          alt=""
          objectFit="contain"
          style={{ aspectRatio: "2400/1080" }}
        />
      </WorkDetailHero>
    );
  };
  */

  const HeroContent = () => {
    return (
      <WorkDetailHero className="">
        <IframeYouTube width="1280" height="720" id="GFgmP-Z8ydk?si=LwXXbBjLk-nVDSFX" />
      </WorkDetailHero>
    );
  };

  const OutlineContent = () => {
    return (
      <React.Fragment>
        <p>
          2024年2月にオープンしたサンフレッチェ広島の新スタジアム「エディオンピースウイング広島」にある「HIROSHIMA SOCCER MUSEUM」のウェルカム映像“RE LIFE サッカーと復興の物語“を、モンタージュが映像演出・制作を担当しました。<br />
          原爆投下によって人々の日常が完全に破壊された広島。絶望の中にあった広島で「負けてなるものか…」という思いで活躍していく広島のサッカー少年たち。その姿は戦後の困難に立ち向かう多くの人々の胸に勇気を呼び起こしていきます。終戦から現在まで、復興発展と共にあった広島サッカーの姿をアニメーションで描いています。新しいスタジアムに受け継がれた平和への意志を作品にこめました。
        </p>
        <dl className="dlWorkCredit mb-0">
          <dt>Producer：</dt>
          <dd>
            大田 俊吾（MONTAGE Inc.）<br />
            柳本 歩規（MONTAGE Inc.）
          </dd>
        </dl>
        <dl className="dlWorkCredit mb-0">
          <dt>Director：</dt>
          <dd>落合 正夫（MONTAGE Inc.）</dd>
        </dl>
        <dl className="dlWorkCredit mb-0">
          <dt>Illustrator：</dt>
          <dd>浦上 和久（東京メロンボーイ）</dd>
        </dl>
        <dl className="dlWorkCredit">
          <dt>Music：</dt>
          <dd>小川 明夏</dd>
        </dl>
      </React.Fragment>
    );
  };

  const summaryData = {
    scopes: ['Produce', 'Art Direction', 'Video Production'],
    awards: [],
    outline: OutlineContent
  };

  /*
  const relatedLinks = [
    {text: "", href: ""}
  ];
  */

  return (
    <WorkDetail
      location={location}
      summary={summaryData}
      heroContent={HeroContent}
      ogpImage={ogpImage}
      //relatedLinks={relatedLinks}
    >
      <WorkDetailImageGallery>
        <WorkDetailImages images={imageNodes.slice(1, 16)} column={1} />
      </WorkDetailImageGallery>
    </WorkDetail>
  );
};

export default WorkDetailPage;

export const query = graphql`
  query {
    images: allFile(
      filter: {relativeDirectory: {eq: "works/hiroshima_museum"}}
      sort: {fields: name, order: ASC}
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1920)
            id
          }
        }
      }
    }
    ogpImage: file(relativePath: {glob: "works/ogp/hiroshima_museum.*"}) {
      publicURL
    }
  }
`;
